import { Contact } from './../../../classes/contact';
import { ActivatedRoute } from '@angular/router';
import { TeamService } from './../../../services/team.service';
import { MainPageData } from './../../../classes/main-page-data';
import { ModelService } from './../../../services/model.service';
import { ZenkitCollections } from './../../../shared/constants/zenkit-collections';
import { DynamicContentService } from './../../../services/dynamic-content.service';
import { Teacher } from './../../../classes/teacher';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-teacher-details',
  templateUrl: './teacher-details.component.html',
  styleUrls: ['./teacher-details.component.scss']
})
export class TeacherDetailsComponent implements OnInit {

  teacher: Teacher;
  contact: Contact;
  teamListShortId = ZenkitCollections.team.shortId;
  contactListShortId = ZenkitCollections.contact.shortId;
  private sub: any;

  constructor(
    private modelService: ModelService,
    private dynamicContentService: DynamicContentService,
    private teamService: TeamService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.modelService.setPageLoaded(false);
    this.modelService.getContact().then((contact: Contact) => {
      this.contact = contact;
      this.modelService.setPageLoaded(true);
    });
  }

  getFileSrc(file) {
    return this.dynamicContentService.getFileSrc(_.get(file, ['shortId']), this.contactListShortId);
  }

  getBackgroundStyle(image) {
    return {
      'background-image': 'url(' + this.getFileSrc(image) + ')'
    };
  }

}
