import { Appointment } from './appointment';

export class CourseInformation {
    uuid: string;
    shortId: string;
    title: string;
    description: string;
    image: string;
    youtubeId: string;
    appointmentUuids: string[];
}
