import { ElementTypes } from './element_types';

export const RequiredElements  = {
    sections: [
        {
            name: 'Titel Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title_ge'
        },
        {
            name: 'Titel Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title_en'
        },
        {
            name: 'Untertitel Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'subtitle_ge'
        },
        {
            name: 'Untertitel Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'subtitle_en'
        },
        {
            name: 'Beschreibung Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description_ge'
        },
        {
            name: 'Beschreibung Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description_en'
        },
        {
            name: 'Beschreibung Deutsch Teil 2',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description_2_ge'
        },
        {
            name: 'Beschreibung Englisch Teil 2',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description_2_en'
        },
        {
            name: 'Bild',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image'
        },
        {
            name: 'Bild Handy',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image_mobile'
        },
        {
            name: 'Bild 2 Deutsch',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image2_ge'
        },
        {
            name: 'Bild 2 Englisch',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image2_en'
        },
        {
            name: 'Mission Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'mission_ge'
        },
        {
            name: 'Mission Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'mission_en'
        },
        {
            name: 'Vision Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'vision_ge'
        },
        {
            name: 'Vision Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'vision_en'
        }
    ],
    home: [
        {
            name: 'Titel',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        },
        {
            name: 'Bild',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image'
        },
        {
            name: 'YouTube Video ID',
            type: ElementTypes.text,
            mappedClassPropertyName: 'youtubeVideoId'
        }
    ],
    current: [
        {
            name: 'Titel',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        },
        {
            name: 'Bilder',
            type: ElementTypes.files,
            mappedClassPropertyName: 'images'
        },
        {
            name: 'YouTube Video ID',
            type: ElementTypes.text,
            mappedClassPropertyName: 'youtubeVideoId'
        },
        {
            name: 'Datum',
            type: ElementTypes.date,
            mappedClassPropertyName: 'date'
        },
    ],
    performances: [
        {
            name: 'Titel',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        },
        {
            name: 'Bild des Posters',
            type: ElementTypes.files,
            mappedClassPropertyName: 'poster'
        },
        {
            name: 'Bildergallerie',
            type: ElementTypes.files,
            mappedClassPropertyName: 'gallery'
        },
        {
            name: 'Datum',
            type: ElementTypes.date,
            mappedClassPropertyName: 'date'
        }
    ],
    courses: [
        {
            name: 'Titel',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        },
        {
            name: 'Datei',
            type: ElementTypes.files,
            mappedClassPropertyName: 'file'
        },
        {
            name: 'Bild',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image'
        },
        {
            name: 'YouTube Video ID',
            type: ElementTypes.text,
            mappedClassPropertyName: 'youtubeId'
        },
        {
            name: 'Termine',
            type: ElementTypes.reference,
            mappedClassPropertyName: 'appointmentUuids'
        }
    ],
    schedule: [
        {
            name: 'Titel',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title'
        },
        {
            name: 'Labels',
            type: ElementTypes.labels,
            mappedClassPropertyName: 'days'
        },
        {
            name: 'Kurs',
            type: ElementTypes.reference,
            mappedClassPropertyName: 'course'
        },
        {
            name: 'Lehrer',
            type: ElementTypes.reference,
            mappedClassPropertyName: 'teacher'
        },
        {
            name: 'Standort',
            type: ElementTypes.reference,
            mappedClassPropertyName: 'location'
        },
        {
            name: 'Schwierigkeitsgrade',
            type: ElementTypes.labels,
            mappedClassPropertyName: 'levels'
        },
        {
            name: 'Altersgruppen',
            type: ElementTypes.labels,
            mappedClassPropertyName: 'ageGroups'
        },
        {
            name: 'Startzeit Stunden',
            type: ElementTypes.number,
            mappedClassPropertyName: 'timeStartHours'
        },
        {
            name: 'Startzeit Minuten',
            type: ElementTypes.number,
            mappedClassPropertyName: 'timeStartMinutes'
        },
        {
            name: 'Endzeit Stunden',
            type: ElementTypes.number,
            mappedClassPropertyName: 'timeEndHours'
        },
        {
            name: 'Endzeit Minuten',
            type: ElementTypes.number,
            mappedClassPropertyName: 'timeEndMinutes'
        }
    ],
    team: [
        {
            name: 'Name',
            type: ElementTypes.text,
            mappedClassPropertyName: 'name'
        },
        {
            name: 'Vorname',
            type: ElementTypes.text,
            mappedClassPropertyName: 'firstName'
        },
        {
            name: 'Nachname',
            type: ElementTypes.text,
            mappedClassPropertyName: 'lastName'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        },
        {
            name: 'Aufgabe',
            type: ElementTypes.text,
            mappedClassPropertyName: 'task'
        },
        {
            name: 'Bild',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image'
        }
    ],
    locations: [
        {
            name: 'Name',
            type: ElementTypes.text,
            mappedClassPropertyName: 'name'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        },
        {
            name: 'Adresse-Name',
            type: ElementTypes.text,
            mappedClassPropertyName: 'addressName'
        },
        {
            name: 'Adresse-Strasse',
            type: ElementTypes.text,
            mappedClassPropertyName: 'addressStreet'
        },
        {
            name: 'Adresse-PLZ',
            type: ElementTypes.text,
            mappedClassPropertyName: 'addressZIP'
        },
        {
            name: 'Adresse-Stadt',
            type: ElementTypes.text,
            mappedClassPropertyName: 'addressCity'
        },
        {
            name: 'Google Maps',
            type: ElementTypes.text,
            mappedClassPropertyName: 'embed'
        },
        {
            name: 'Bild',
            type: ElementTypes.files,
            mappedClassPropertyName: 'image'
        },
        {
            name: 'Bildergalerie',
            type: ElementTypes.files,
            mappedClassPropertyName: 'gallery'
        },
        {
            name: 'Initialien',
            type: ElementTypes.text,
            mappedClassPropertyName: 'initials'
        }// ,
        // {
        //     name: '1. Beratungszeit',
        //     type: ElementTypes.text,
        //     mappedClassPropertyName: 'consultationTime1'
        // },
        // {
        //     name: '2. Beratungszeit',
        //     type: ElementTypes.text,
        //     mappedClassPropertyName: 'consultationTime2'
        // },
        // {
        //     name: '3. Beratungszeit',
        //     type: ElementTypes.text,
        //     mappedClassPropertyName: 'consultationTime3'
        // }
    ],
    contact: [
        {
            name: 'Name',
            type: ElementTypes.text,
            mappedClassPropertyName: 'name'
        },
        {
            name: 'E-Mail',
            type: ElementTypes.text,
            mappedClassPropertyName: 'email'
        },
        {
            name: 'Telefon',
            type: ElementTypes.text,
            mappedClassPropertyName: 'phone'
        },
        {
            name: 'Telefon Grüne Box',
            type: ElementTypes.text,
            mappedClassPropertyName: 'phone_green_box'
        },
        {
            name: 'Mobil',
            type: ElementTypes.text,
            mappedClassPropertyName: 'mobile'
        },
        {
            name: 'Impressum Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'imprint_ge'
        },
        {
            name: 'Impressum Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'imprint_en'
        },
        {
            name: 'Datenschutz Deutsch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'privacy_ge'
        },
        {
            name: 'Datenschutz Englisch',
            type: ElementTypes.text,
            mappedClassPropertyName: 'privacy_en'
        },
        {
            name: 'Straße und Hausnummer',
            type: ElementTypes.text,
            mappedClassPropertyName: 'address_street'
        },
        {
            name: 'Stadt und PLZ',
            type: ElementTypes.text,
            mappedClassPropertyName: 'address_city'
        },
        {
            name: 'Sprachen Selektion anzeigen',
            type: ElementTypes.checkbox,
            mappedClassPropertyName: 'display_language_selection'
        }         
    ],
    imprint: [
        {
            name: 'Titel',
            type: ElementTypes.text,
            mappedClassPropertyName: 'title'
        },
        {
            name: 'Beschreibung',
            type: ElementTypes.text,
            mappedClassPropertyName: 'description'
        }
    ]
};
