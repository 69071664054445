import { ElementTypes } from '../shared/constants/element_types';

export class Contact {
    name: string;
    email: string;
    phone: string;
    phone_green_box: string;
    mobile: string;
    imprint_ge: string;
    imprint_en: string;
    description: string;
    image: string;
    privacy_ge: string;
    privacy_en: string;
    address_street: string;
    address_city: string;
    display_language_selection: boolean;
}
