import { MainPageSection2 } from './../classes/main-page-section-2';
import { forEach } from '@angular/router/src/utils/collection';
import { MainPageData } from './../classes/main-page-data';
import { ModelService } from './../services/model.service';
import { ZenkitCollections } from './../shared/constants/zenkit-collections';
import { DynamicContentService } from './../services/dynamic-content.service';
import { Component, OnInit } from '@angular/core';
import { MainPageService } from './../services/main-page.service';
import { MainPageSection } from './../classes/main-page-section';
import * as _ from 'lodash';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Contact } from '../classes/contact';


@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  mainPageListShortId: string;
  titleSection: MainPageSection2;
  sections: MainPageSection2[] = [];
  contact: Contact;

  contentLoaded = false;
  // loadVideoTimeout = 8000;
  // removeVideo = false;
  titleImageLoaded = false;
  displayTitleImage = false;
  // videoLoaded = false;
  showTitleImageTimeout = 0;
  showTitleImageTimeoutPassed = false;
  // playVideo = false;
  pageLoaded = false;
  languageIsEnglish: boolean;
  // youtubeVideoId: string;
  showTitleTimeout = false;
  hideTitle = true;
  // videoElement: any;
  isBrowser: boolean;

  constructor(
    private dynamicContentService: DynamicContentService,
    private modelService: ModelService,
    private domSanitizer: DomSanitizer) { }

    ngOnInit() {
      this.languageIsEnglish = this.modelService.language.getValue() === 'EN' ? true : false;

      this.modelService.language.subscribe((language: string) => {
        this.languageIsEnglish = this.modelService.language.getValue() === 'EN' ? true : false;
      });

      this.pageLoaded = this.modelService.setPageLoaded(false);
      this.isBrowser = this.modelService.isPlatformBrowser();
      if (this.isBrowser) {
        this.isBrowser = true;
        console.log('platform is browser');
      } else {
        this.isBrowser = false;
        console.log('platform is server');
      }

      this.mainPageListShortId = ZenkitCollections.home.shortId;

      Promise.all([this.modelService.getMainPageSections2(), this.modelService.getContact()]).then(data => {
        const mainPageSections: MainPageSection2[] = data[0];
        this.contact = data[1];

        this.titleSection = _.head(mainPageSections);

        this.sections = mainPageSections.slice(1);

        this.contentLoaded = true;
        this.pageLoaded = this.modelService.setPageLoaded(true);

        if (this.isBrowser) {
          // remove video timeout
          // setTimeout(() => {
          //   if (this.videoLoaded === false) {
          //     this.removeVideo = true;
          //   }
          // }, this.loadVideoTimeout);

          // play video after this timeout
          setTimeout(() => {
            this.showTitleImageTimeoutPassed = true;
          }, this.showTitleImageTimeout);
        }

        setTimeout(() => {
          const intersectionObserver2 = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting === true) {
              entries[0].target.classList.add('visible');
            }
          }, { threshold: [0.3] });

          const blockElements = document.getElementsByClassName('main-page-block');
          const blockElementsArray = Array.from(blockElements);

          blockElementsArray.forEach((el) => {
            intersectionObserver2.observe(el);
          });

          blockElementsArray.forEach(el => {
            if (this.isIntersecting(el)) {
              el.classList.add('visible');
            }
          });
        }, 500);

      // window.addEventListener('scroll', () => {
        // blockElementsArray.forEach(el => {
        //   if (this.isIntersecting(el)) {
        //     el.classList.add('visible');
        //   }
        // });
      // });
      });
    }

    isIntersecting(element: Element) {
      const position = element.getBoundingClientRect();
      // checking whether fully visible
      if (position.top >= 0 && position.bottom <= window.innerHeight) {
        return true;
      }
      // checking for partial visibility
      if (position.top < window.innerHeight && position.bottom >= 0) {
        return true;
      }
      return false;
    }

    setVisiblityAfterTimeout(element: Element, timeout: number) {
      setTimeout(() => {
        element.classList.add('visible');
      }, timeout);
    }

    getFileSrc(file) {
      return this.dynamicContentService.getFileSrc(_.get(file, ['shortId']), this.mainPageListShortId);
    }

    // setVideoElement() {
    //   if (_.isNil(this.videoElement)) {
    //     this.videoElement = document.getElementById('video');
    //   }
    //   return this.videoElement;
    // }

    // getYoutubeLink() {
    //   // tslint:disable-next-line:max-line-length
    //   const url = 'https://www.youtube.com/embed/'  + this.youtubeVideoId + '?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;mute=1&amp;playlist=' + this.youtubeVideoId;
    //   return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    // }

    getBackgroundStyle(image) {
      const backgroundStyle = {
        'background-image': 'url(' + this.getFileSrc(image) + ')'
      };
      return backgroundStyle;
    }

    // playVideoButtonClicked() {
    //   this.hideTitle = true;
    //   this.displayTitleImage = false;
    // }

    imageLoaded() {
      console.log('title image loaded!');
      this.titleImageLoaded = true;
      this.hideTitle = false;
      this.displayTitleImage = true;
      this.checkIfTitleImageShouldBeDisplayed();
    }

    checkIfTitleImageShouldBeDisplayed() {
      if (this.isBrowser) {
        if (this.showTitleImageTimeoutPassed) {
          this.displayTitleImage = true;
          this.hideTitle = false;
        } else {
          setTimeout(() => {
            this.checkIfTitleImageShouldBeDisplayed();
          }, 200);
        }
      }
    }

    videoDataLoaded() {
      console.log('title video loaded!');
      // this.videoLoaded = true;
      // this.checkIfVideoShouldBePlayed();
    }

    // checkIfVideoShouldBePlayed() {
    //   if (this.isBrowser) {
    //     if (this.showTitleImageTimeoutPassed) {
    //       this.setVideoElement();
    //       this.videoElement.muted = true;
    //       this.videoElement.autoplay = true;
    //       this.videoElement.play();
    //       this.playVideo = true;
    //     } else {
    //       setTimeout(() => {
    //         this.checkIfVideoShouldBePlayed();
    //       }, 200);
    //     }
    //   }
    // }

    scrollIntoView(elementId: string) {
      const element = document.getElementById(elementId);
      element.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
}
