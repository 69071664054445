import { RequiredElements } from './required-elements';

export const ZenkitCollections  = {
    home: {
        shortId: 'H1aA_3bLX',
        requiredElements: RequiredElements.home
    },
    sections: {
        shortId: '7L87PV4Zg',
        requiredElements: RequiredElements.sections
    },
    current: {
        shortId: 'S1PZ5AbcZ',
        requiredElements: RequiredElements.current
    },
    performances: {
        shortId: 'HyltubcKub',
        requiredElements: RequiredElements.performances
    },
    courses: {
        shortId: 'ByeBytnWIQ',
        requiredElements: RequiredElements.courses
    },
    schedule: {
        shortId: 'BJ1gKhbIm',
        requiredElements: RequiredElements.schedule
    },
    team: {
        shortId: 'BJ6t-cFub',
        requiredElements: RequiredElements.team
    },
    locations: {
        shortId: 'BJugtn-87',
        requiredElements: RequiredElements.locations
    },
    contact: {
        shortId: 'Lxabl_dgl',
        requiredElements: RequiredElements.contact
    },
    imprint: {
        shortId: 'HkLziF2WUm',
        requiredElements: RequiredElements.imprint
    }
};
