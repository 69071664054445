export class MainPageSection2 {
    title_ge: string;
    title_en: string;
    subtitle_ge: string;
    subtitle_en: string;
    description_ge: string;
    description_en: string;
    description_2_ge: string;
    description_2_en: string;
    cssClass: string;
    image: string;
    image_mobile: string;
    image2_ge: string;
    image2_en: string;
    routerLink: string;
    ankerId: string;
    mission_ge: string;
    mission_en: string;
    vision_ge: string;
    vision_en: string;

    navigation_dom_id: string;
    isHighlightedInNavigation: boolean;
    scrollYStart: number;
    scrollYEnd: number;
}
