import { MainPageSection2 } from './../classes/main-page-section-2';
import { CourseInformation } from './../classes/course-information';
import { MainPageComponent } from './../main-page/main-page.component';
import { MainPageSectionTypes } from './../shared/constants/main-page-section-types';
import { ZenkitCollections } from './../shared/constants/zenkit-collections';
import { DynamicContentService } from './dynamic-content.service';
import { Injectable } from '@angular/core';
import { MainPageSection } from './../classes/main-page-section';
import * as _ from 'lodash';
import { MainPageData } from '../classes/main-page-data';

@Injectable()
export class MainPageService2 {

  constructor(private dynamicContentService: DynamicContentService) { }

  getMainPageSections() {
    return this.dynamicContentService
      .fetchAndTransformZenkitListData(ZenkitCollections.sections.shortId)
      .then((zenkitListData) => {
        let index = 0;
        const mainPageSections = _.map(zenkitListData.entries, (modifiedEntry) => {
          const mainPageSection = new MainPageSection2();
          mainPageSection.title_ge = modifiedEntry.title_ge;
          mainPageSection.title_en = modifiedEntry.title_en;
          mainPageSection.subtitle_ge = modifiedEntry.subtitle_ge;
          mainPageSection.subtitle_en = modifiedEntry.subtitle_en;
          mainPageSection.description_ge = modifiedEntry.description_ge;
          mainPageSection.description_en = modifiedEntry.description_en;
          mainPageSection.description_2_ge = modifiedEntry.description_2_ge;
          mainPageSection.description_2_en = modifiedEntry.description_2_en;
          mainPageSection.mission_en = modifiedEntry.mission_en;
          mainPageSection.mission_ge = modifiedEntry.mission_ge;
          mainPageSection.vision_en = modifiedEntry.vision_en;
          mainPageSection.vision_ge = modifiedEntry.vision_ge;

          let domId: string;
          if (mainPageSection.title_en) {
            domId = mainPageSection.title_en.toLowerCase().replace(' ', '-');
          } else {
            domId = index.toString();
          }

          index++;

          mainPageSection.isHighlightedInNavigation = false;
          mainPageSection.ankerId = 'anker-' + domId;
          mainPageSection.navigation_dom_id = 'navigation-element-' + domId;
          mainPageSection.scrollYStart = -1;
          mainPageSection.scrollYEnd = -1;

          const image = _.head(modifiedEntry.image);
          if (image) {
            mainPageSection.image = this.dynamicContentService.getFileSrc(_.get(image, ['shortId']), ZenkitCollections.sections.shortId);
          }
          const image_mobile = _.head(modifiedEntry.image_mobile);
          if (image_mobile) {
            // tslint:disable-next-line:max-line-length
            mainPageSection.image_mobile = this.dynamicContentService.getFileSrc(_.get(image_mobile, ['shortId']), ZenkitCollections.sections.shortId);
          }
          const image2_ge = _.head(modifiedEntry.image2_ge);
          if (image2_ge) {
            // tslint:disable-next-line:max-line-length
            mainPageSection.image2_ge = this.dynamicContentService.getFileSrc(_.get(image2_ge, ['shortId']), ZenkitCollections.sections.shortId);
          }

          const image2_en = _.head(modifiedEntry.image2_en);
          if (image2_en) {
            // tslint:disable-next-line:max-line-length
            mainPageSection.image2_en = this.dynamicContentService.getFileSrc(_.get(image2_en, ['shortId']), ZenkitCollections.sections.shortId);
          }

          return mainPageSection;
        });

        return mainPageSections;
      });
    }

}
