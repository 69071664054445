import { ZenkitCollections } from './../shared/constants/zenkit-collections';
import { DynamicContentService } from './../services/dynamic-content.service';
import { Contact } from './../classes/contact';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ContactService {

  constructor(private dynamicContentService: DynamicContentService) { }

  getContact() {
    return this.dynamicContentService
      .fetchAndTransformZenkitListData(ZenkitCollections.contact.shortId)
      .then((zenkitListData) => {
        const modifiedEntry: any = _.head(zenkitListData.entries);
        const contact = new Contact();
        contact.name = modifiedEntry.name;
        contact.email = modifiedEntry.email;
        contact.phone = modifiedEntry.phone;
        contact.phone_green_box = modifiedEntry.phone_green_box;
        contact.mobile = modifiedEntry.mobile;
        contact.imprint_ge = modifiedEntry.imprint_ge;
        contact.imprint_en = modifiedEntry.imprint_en;
        contact.description = modifiedEntry.description;
        contact.image = _.head(modifiedEntry.image);
        contact.privacy_ge = modifiedEntry.privacy_ge;
        contact.privacy_en = modifiedEntry.privacy_en;
        contact.address_street = modifiedEntry.address_street;
        contact.address_city = modifiedEntry.address_city;
        contact.display_language_selection = modifiedEntry.display_language_selection;
        return contact;
      });
  }

}
