import { PageSection } from './header.component';
import { MainPageSection2 } from './../../classes/main-page-section-2';
import { Component, OnInit } from '@angular/core';
import { ModelService } from '../../services/model.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {

  isMobileNavOpen = false;
  pageLoaded;
  pageInitiallyLoaded;
  pageIsHome;
  isBrowser;
  scrolledToTop = true;
  last_known_scroll_position: number;
  scrolledToBottom: boolean;
  updateNavigationIsRunning = false;
  languageIsEnglish: boolean;
  display_language_selection = false;

  pageSections: MainPageSection2[] = [];

  constructor(
    private modelService: ModelService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isBrowser = this.modelService.isPlatformBrowser();
    if (this.isBrowser) {
      this.pageLoaded = true; // true
      this.pageInitiallyLoaded = false;
    } else {
      this.pageLoaded = true;
      this.pageInitiallyLoaded = true;
    }

    this.languageIsEnglish = this.modelService.language.getValue() === 'EN' ? true : false;

    this.modelService.language.subscribe((language: string) => {
      this.languageIsEnglish = this.modelService.language.getValue() === 'EN' ? true : false;
    });

    this.modelService.getMainPageSections2().then((sections: MainPageSection2[]) => {
      this.pageSections = sections.slice(1);
    });

    this.modelService.getContact().then(contact => {
      this.display_language_selection = contact.display_language_selection;
    });

    this.modelService.isPageLoaded().subscribe(
      async (x) => {
        if (this.modelService.isPlatformBrowser()) {
          this.pageLoaded = x;
          if (x && !this.pageInitiallyLoaded) {
            this.pageInitiallyLoaded = true;
          }
          await this.timeout(1000);
          this.setScrollPositions();
          if (!this.updateNavigationIsRunning) {
            this.updateNavigation();
          }
        }
        if (this.modelService.isPlatformBrowser()) {
          window.addEventListener('scroll', (e) => {
            this.setScrollPositions();
            this.updateHighlightedPageSection();
          });
          window.addEventListener('resize', (e) => {
            this.setScrollPositions();
            this.setPageSectionPositions();
            this.updateHighlightedPageSection();
          });
        }
      }
    );

    this.router.events.subscribe((evt: any) => {
      this.pageIsHome = evt.url === '/';
    });
  }

  async timeout(time: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }

  updateNavigation() {
    setTimeout(() => {
      this.setScrollPositions();
      this.setPageSectionPositions();
      this.updateHighlightedPageSection();
      this.updateNavigation();
    }, 1000);
  }

  setScrollPositions() {
    this.last_known_scroll_position = window.scrollY;
    // console.log(window.scrollY);
    this.scrolledToTop = this.last_known_scroll_position === 0;
    const lastElement = this.pageSections[this.pageSections.length - 1];
    if (lastElement.scrollYEnd) {
      this.scrolledToBottom = ((window.innerHeight + window.scrollY) >= lastElement.scrollYEnd);
    }
  }

  setPageSectionPositions() {
    this.pageSections.forEach((pageSection) => {
      const element = document.getElementById(pageSection.ankerId);
      if (element) {
        pageSection.scrollYStart = element.offsetTop - 100;
        pageSection.scrollYEnd = element.offsetTop + element.clientHeight - 100;
      }
    });
  }

  updateHighlightedPageSection() {
    this.pageSections.forEach((pageSection: MainPageSection2) => {
      if (pageSection.scrollYStart && pageSection.scrollYEnd) {
        if (this.last_known_scroll_position >= pageSection.scrollYStart
          && this.last_known_scroll_position <= pageSection.scrollYEnd
           && !this.scrolledToBottom) {
          pageSection.isHighlightedInNavigation = true;
        } else {
          pageSection.isHighlightedInNavigation = false;
        }
      }

      if (this.scrolledToBottom) {
        this.pageSections[this.pageSections.length - 1].isHighlightedInNavigation = true;
      }
    });
  }

  toggleMobileNav() {
    this.isMobileNavOpen = !this.isMobileNavOpen;
  }

  closeMobileNav() {
    this.isMobileNavOpen = false;
  }

  scrollIntoView(elementId: string) {
    this.isMobileNavOpen = false;
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: 'smooth', block: 'start'});
  }

  switchLanguage() {
    const nextLanguage = this.languageIsEnglish ? 'GE' : 'EN';
    this.modelService.language.next(nextLanguage);
  }

}


export interface PageSection {
  displayName: string;
  ankerId: string;
  domId: string;
  isHighlightedInNavigation: boolean;
  scrollYStart: number;
  scrollYEnd: number;
}
